import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from "@angular/core";
import { Router } from "@angular/router";
import { NB_AUTH_OPTIONS } from "../../auth.options";
import { getDeepFromObject } from "../../helpers";
import { NgForm } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { Constant } from "../../constant/constant";
import {
  AnimationSettingsModel,
  SpinSettingsModel,
} from "@syncfusion/ej2-splitbuttons";

@Component({
  selector: "request-password-page",
  styleUrls: ["./vta.request-password.component.scss"],
  templateUrl: "./vta.request-password.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestPasswordComponent {
  requestPasswordActionMessage: string;
  showSuccessMessage: boolean = false;
  showErrorMessage: boolean = false;
  submitted: boolean = false;
  emailAddress: string;
  slideRight: AnimationSettingsModel = { effect: "SlideRight" };
  spinCenter: SpinSettingsModel = { position: "Center" };
  emailValidationPattern: string | RegExp = "";

  constructor(
    private apiService: ApiService,
    private constant: Constant,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router
  ) {
    this.emailValidationPattern = this.constant.EMAIL_VALIDATION_PATTERN;
  }

  requestPass(requestPassForm: NgForm): void {
    this.submitted = true;
    const payload = {
      email: ((requestPassForm || {}).value || {}).email || "",
    };
    this.apiService.requestPassword(payload).subscribe(
      (data) => {
        this.showSuccessMessage = true;
        this.showErrorMessage = false;
        this.requestPasswordActionMessage =
          this.constant.REQUEST_PASSWORD_MSG.SUCCESS;
        requestPassForm.resetForm();
        this.submitted = false;
      },
      (err) => {
        this.showErrorMessage = true;
        this.showSuccessMessage = false;
        this.requestPasswordActionMessage =
          this.constant.REQUEST_PASSWORD_MSG.FAILURE;
        requestPassForm.resetForm();
        this.submitted = false;
      },
      () => {}
    );
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
