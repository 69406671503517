<div class="row center">
  <div class="col-md-4 col-xs-12">
    <section class="another-action mr-t-0">
      <img src="assets/images/easyVTA_logo_for_whiteBG.png" alt="" height="1%" width="100%">
      <!--
      The line below is longer then the password box. Line break is inserted between sentences
      to avoid only the word registration being on the new line
      -->
      <p>Welcome to easyVTA. <br> Please create a password to complete registration.</p>
    </section>
    <form (ngSubmit)="verifyUser(resetPassForm)" #resetPassForm="ngForm" aria-labelledby="title">

        <div class="form-group template">
          <div id="pass" class="e-float-input" [ngClass]="{'e-success': password.valid}">
            <input type="password" id="input-password" name="password" [(ngModel)]="newPassword"
              #password="ngModel" required pattern="(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d$@$!%#^*?&].{11,}">
            <span class="e-float-line"></span>
            <label class="e-float-text"
              [ngClass]="{'e-label-top': password !== '' , 'e-label-bottom':  password == ''}"
              for="name">Create Password</label>
            <nb-icon *ngIf="!showPasswordFlag" class="pass-icon" icon="eye-off-outline"
              (click)="togglePassword('input-password')"></nb-icon>
            <nb-icon *ngIf="showPasswordFlag" class="pass-icon" icon="eye-outline"
              (click)="togglePassword('input-password')">
            </nb-icon>
          </div>
          <ng-container *ngIf="password.invalid && password.touched">
            <p class="caption status-danger" *ngIf="password.errors?.required">
              Password is required!
            </p>
            <p class="caption status-danger" *ngIf="password.dirty">
              Password should contains minimum 12 characters, one lower case and one upper case.
            </p>
          </ng-container>
        </div>

        <div class="form-group template">
          <div class="e-float-input" [ngClass]="{'e-success': password.valid}">
            <input type="password" id="input-re-password" name="rePass" [(ngModel)]="confirmPassword"
              #rePass="ngModel" required pattern="(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d$@$!%#^*?&].{11,}">
            <span class="e-float-line"></span>
            <label class="e-float-text"
              [ngClass]="{'e-label-top': rePass !== '' , 'e-label-bottom':  rePass == ''}"
              for="name">Confirm Password</label>
            <nb-icon *ngIf="!showRePasswordFlag" class="pass-icon" icon="eye-off-outline"
              (click)="togglePassword('input-re-password')"></nb-icon>
            <nb-icon *ngIf="showRePasswordFlag" class="pass-icon" icon="eye-outline"
              (click)="togglePassword('input-re-password')"></nb-icon>
          </div>
          <ng-container *ngIf="rePass.touched">
            <p class="caption status-danger" *ngIf="rePass.invalid && rePass.errors?.required">
              Password confirmation is required!
            </p>
            <p class="caption status-danger"
              *ngIf="password.value != rePass.value && !rePass.errors?.required">
              Password does not match the confirm password.
            </p>
          </ng-container>
        </div>

        <div class="form-group template center">
          <div class="submitBtn">

            <button style="width: 10vw;" class="submit-btn e-btn" id="submit-btn" ejs-progressbutton
              content="Save Password" [enableProgress]="true" [spinSettings]="spinCenter"
            [animationSettings]="slideRight" cssClass="e-outline e-success" type="submit"
            [disabled]="submitted || !resetPassForm.valid || password.value != rePass.value ">Save
            Password</button>
        </div>
      </div>
    </form>
  </div>
</div>