<div id="request-pass" class="row center">
  <div class="col-md-4 col-xs-12">
    <section class="another-action mr-t-0">
      <img
        src="assets/images/easyVTA_logo_for_whiteBG.png"
        alt=""
        height="1%"
        width="100%"
      />
    </section>
    <p class="sub-title">
      Enter your email address and we’ll send a link to reset your password
    </p>
    <nb-alert *ngIf="showErrorMessage" outline="danger" role="alert">
      <p class="alert-title">
        <b>{{ requestPasswordActionMessage }}</b>
      </p>
    </nb-alert>
    <nb-alert *ngIf="showSuccessMessage" outline="success" role="alert">
      <p class="alert-title">
        <b>{{ requestPasswordActionMessage }}</b>
      </p>
    </nb-alert>
    <form (ngSubmit)="requestPass(form)" #form="ngForm" aria-labelledby="title">
      <div class="form-group template">
        <div
          id="mobile-no"
          class="e-float-input"
          [ngClass]="{ 'e-success': email.valid }"
        >
          <input
            type="text"
            id="email"
            name="email"
            [(ngModel)]="emailAddress"
            #email="ngModel"
            required
            [pattern]="emailValidationPattern"
          />
          <span class="e-float-line"></span>
          <label
            class="e-float-text"
            [ngClass]="{
              'e-label-top': email !== '',
              'e-label-bottom': email == ''
            }"
            for="name"
            >Email</label
          >
        </div>
        <span
          class="e-error"
          *ngIf="
            email.errors &&
            email.errors.required &&
            (email.dirty || email.touched)
          "
        >
          Enter your email address
        </span>
        <span
          class="e-error"
          *ngIf="
            email.errors &&
            email.errors.pattern &&
            (email.dirty || email.touched)
          "
        >
          Enter a valid email address
        </span>
      </div>
      <div class="form-group template center">
        <div class="submitBtn">
          <button
            style="width: 15vw"
            class="submit-btn e-btn"
            id="submit-btn"
            ejs-progressbutton
            content="Request Password"
            [enableProgress]="true"
            [spinSettings]="spinCenter"
            [animationSettings]="slideRight"
            cssClass="e-outline e-success"
            type="submit"
            [disabled]="submitted || !form.valid"
          >
            Request Password
          </button>
        </div>
      </div>
    </form>
    <section class="another-action" aria-label="Sign in or sign up">
      <a class="text-link" routerLink="../login">Back to Log In</a>
    </section>
    <section class="another-action" aria-label="Register">
      <a routerLink="../register" class="text-link">Register</a>
    </section>
  </div>
</div>
