import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { StartupService } from '../../services/startup.service';
import { Constant } from '../../constant/constant';

@Component({
  selector: 'approve-request',
  templateUrl: './vta.approve-request.component.html',
  styleUrls: ['./vta.approve-request.component.scss']
})
export class ApproveRequestComponent implements OnInit {

  approveToken: string;
  loading : boolean = false;
  constructor(private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private startup : StartupService,
    private router : Router,
    private constant: Constant,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.approveToken = ((params || {}).requestid || '').replace(/\s+/g, '+');
    });
    this.getVacationRequestFromEmail();
  }

  getVacationRequestFromEmail() {
    this.loading = true;
    this.apiService.getVacationRequestFromEmail(this.approveToken).subscribe(
      data => {
        let approveData = {
          status: ((data || {}).details || {}).status || '',
          id: (data || {}).id || ''
        }
        let vacationRequestData = JSON.parse(localStorage.getItem("vacationRequestData"));
        if(!!vacationRequestData && !!vacationRequestData.id){
          localStorage.removeItem("vacationRequestData");
        }
        localStorage.setItem("vacationRequestData", JSON.stringify(approveData));
        this.loading = false;
        this.redirectToPage();
      },
      (err) => {
        this.loading = false;
        this.redirectToPage();
      },
      () => { }
    );
  }

  redirectToPage(){
    if(this.startup.getNavigationData.loggedInStatus && this.startup.getNavigationData.isTokenValid){
      this.router.navigateByUrl(this.constant.REDIRECT_LINKS.DASHBOARD);
    }else{
      this.router.navigateByUrl(this.constant.REDIRECT_LINKS.LOGIN);
    }
  }

}
