import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { NB_AUTH_OPTIONS } from "../../auth.options";
import { getDeepFromObject } from "../../helpers";
import { Register } from "../../models/vta.register";
import { ApiService } from "../../services/api.service";
import { Constant } from "../../constant/constant";
import { NgForm } from "@angular/forms";
import { NbToastrService } from "@nebular/theme";
import {
  AnimationSettingsModel,
  SpinSettingsModel,
} from "@syncfusion/ej2-splitbuttons";

@Component({
  selector: "register",
  styleUrls: ["./vta.register.component.scss"],
  templateUrl: "./vta.register.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
  user: Register = new Register();
  registerActionMessage: string;
  showSuccessMessage: boolean = false;
  showErrorMessage: boolean = false;
  loading: boolean = false;
  slideRight: AnimationSettingsModel = { effect: "SlideRight" };
  spinCenter: SpinSettingsModel = { position: "Center" };
  emailValidationPattern: string | RegExp = "";

  constructor(
    private apiService: ApiService,
    private toastrService: NbToastrService,
    private constant: Constant,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router
  ) {
    this.emailValidationPattern = this.constant.EMAIL_VALIDATION_PATTERN;
  }

  ngOnInit() {}

  register(form: NgForm): void {
    const payload = this.createPayload(this.user);
    this.loading = true;
    this.apiService.createOrganization(payload).subscribe(
      (data) => {
        this.showSuccessMessage = true;
        this.registerActionMessage = this.constant.REGISTER_MSG.SUCCESS;
        this.loading = false;
        form.resetForm();
      },
      (err) => {
        this.showErrorMessage = true;
        this.registerActionMessage = this.constant.REGISTER_MSG.FAILURE;
        this.showToast(
          this.constant.TOAST_ACTION.FAILURE,
          this.registerActionMessage
        );
        this.loading = false;
        form.resetForm();
      },
      () => {}
    );
  }

  createPayload(user: Register) {
    let name = {
      first: (user || {}).firstName || "",
      middle: (user || {}).middleName || "",
      last: (user || {}).lastName || "",
    };
    let registerData = {
      email: (this.user || {}).email || "",
      name: name,
    };
    let admin = [];
    admin.push(registerData);

    const actionPayload = {
      name: (this.user || {}).name || "",
      admins: admin,
    };
    return actionPayload;
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
  showToast(status: any, msg: string) {
    this.toastrService.show("", msg, { status });
  }
}
