import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthState } from '../store/app.states';
import { Store } from '@ngrx/store';
import { Logout } from '../store/actions/authentication.actions';
@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private store: Store<AuthState>) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    },(err: any) => {
        this.handleAuthError(err);
      }));
  }
  private handleAuthError(err: HttpErrorResponse) {
    if (err.status === 401 || err.status === 403) {
      this.store.dispatch(new Logout());
    }
  }
}