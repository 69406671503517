<div class="row center">
  <div class="col-md-4 col-xs-12">
    <section class="another-action mr-t-0">
      <img
        src="assets/images/easyVTA_logo_for_whiteBG.png"
        alt=""
        height="1%"
        width="100%"
      />
    </section>

    <form (ngSubmit)="onSubmit()" #form="ngForm" aria-labelledby="title">
      <div class="form-group template">
        <div
          id="mobile-no"
          class="e-float-input"
          [ngClass]="{ 'e-success': email.valid }"
        >
          <input
            type="text"
            id="email"
            name="email"
            [(ngModel)]="user.email"
            #email="ngModel"
            required
            [pattern]="emailValidationPattern"
          />
          <span class="e-float-line"></span>
          <label
            class="e-float-text"
            [ngClass]="{
              'e-label-top': user.email !== '',
              'e-label-bottom': user.email == ''
            }"
            for="name"
            >Email</label
          >
        </div>
        <span
          class="e-error"
          *ngIf="
            email.errors &&
            email.errors.required &&
            (email.dirty || email.touched)
          "
        >
          Enter your email address
        </span>
        <span
          class="e-error"
          *ngIf="
            email.errors &&
            email.errors.pattern &&
            (email.dirty || email.touched)
          "
        >
          Enter a valid email address
        </span>
      </div>

      <div class="form-group template">
        <div
          id="pass"
          class="e-float-input"
          [ngClass]="{ 'e-success': password.valid }"
        >
          <input
            type="password"
            id="input-password"
            name="password"
            [(ngModel)]="user.password"
            #password="ngModel"
            required
            pattern="(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d$@$!%#^*?&].{11,}"
          />
          <span class="e-float-line"></span>
          <label
            class="e-float-text"
            [ngClass]="{
              'e-label-top': user.password !== '',
              'e-label-bottom': user.password == ''
            }"
            for="name"
            >Password</label
          >
          <nb-icon
            *ngIf="!showPasswordFlag"
            class="pass-icon"
            icon="eye-off-outline"
            (click)="togglePassword('input-password')"
          ></nb-icon>
          <nb-icon
            *ngIf="showPasswordFlag"
            class="pass-icon"
            icon="eye-outline"
            (click)="togglePassword('input-password')"
          >
          </nb-icon>
        </div>
        <span
          class="e-error"
          *ngIf="
            password.errors &&
            password.errors.required &&
            (password.dirty || password.touched)
          "
        >
          Enter password
        </span>
        <span
          class="e-error"
          *ngIf="
            password.errors &&
            password.errors.pattern &&
            (password.dirty || password.touched)
          "
        >
          Password should contains minimum 12 characters, one lower case and one
          upper case.
        </span>
      </div>

      <div class="form-group template center">
        <div class="submitBtn">
          <button
            style="width: 10vw"
            class="submit-btn e-btn"
            id="submit-btn"
            ejs-progressbutton
            content="SIGN IN"
            [enableProgress]="true"
            [spinSettings]="spinCenter"
            [animationSettings]="slideRight"
            cssClass="e-outline e-success"
            type="submit"
            [disabled]="submitted || !form.valid"
          >
            SIGN IN
          </button>
        </div>
      </div>
    </form>

    <section class="another-action" aria-label="Register">
      To Register <a class="text-link" routerLink="../register">Click here</a>
    </section>

    <section class="another-action" aria-label="Forgot Password">
      Forgot the password?
      <a class="text-link" routerLink="../request-password">Click here</a>
    </section>
  </div>
</div>
