
import { Component, HostListener, OnInit } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { SeoService } from './@core/utils/seo.service';
import { StartupService } from './@theme/components/auth/services/startup.service';
import { Router } from '@angular/router';
import { Constant } from './@theme/components/auth/constant/constant';
import { ApiService } from './@theme/components/auth/services/api.service';
import { UserService } from './@theme/components/auth/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'vta-app',
  template: '<router-outlet></router-outlet> <vta-loader></vta-loader>',
})
export class AppComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent) { 
    if(event.keyCode == 40)
		document.querySelector(".scrollable-container").scrollTop += 100
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardUpEvent(event: KeyboardEvent) { 
    if(event.keyCode == 38)
		document.querySelector(".scrollable-container").scrollTop -= 100
  }
  constructor(private analytics: AnalyticsService, private seoService: SeoService,
    private startup : StartupService,
    private router : Router,
    private constant: Constant,
    private apiService : ApiService,
    private userService: UserService,
    private bnIdle: BnNgIdleService) {
  }

  ngOnInit(): void {
    this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        console.log('session expired');
        localStorage.clear();
        let url = this.constant.REDIRECT_LINKS.LOGIN;
        this.router.navigateByUrl(url);
      }
    });
    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();
    this.redirectToPath();
  }
  
  redirectToPath() {
    if (location.pathname == '/verify' || location.pathname == '/resetpassword' || location.pathname == '/home'){
      
    }else if (location.pathname != '/approve') {
      if (this.startup.getNavigationData.loggedInStatus && this.startup.getNavigationData.isTokenValid && (location.pathname == '/' || location.pathname == this.constant.REDIRECT_LINKS.LOGIN || location.pathname == this.constant.REDIRECT_LINKS.REGISTER)) {
        this.router.navigateByUrl(this.constant.REDIRECT_LINKS.DASHBOARD);
      }else if(this.startup.getNavigationData.loggedInStatus && this.startup.getNavigationData.isTokenValid && location.pathname != '/'){
        this.router.navigateByUrl(location.pathname);
      }else if(this.startup.getNavigationData.loggedInStatus && !this.startup.getNavigationData.isTokenValid){
        this.router.navigateByUrl(this.constant.REDIRECT_LINKS.LOGIN);
      }
    }
  }
}
